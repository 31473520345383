<template>
  <BaseDialog :model-value="true" size-class="!max-w-2xl" @update:model-value="$emit('close')">
    <template #header>
      {{ updateFolder ? 'Edit Folder' : 'Create Folder' }}
    </template>
    <template #default>
      <div class="flex-col items-center space-y-2 px-1 py-4">
        <div class="text-sm font-medium text-gray-100">Folder Name</div>
        <BaseTextbox placeholder="Enter Folder Name" v-model="folderName" maxlength="30" />
        <span v-if="folderNameError" class="text-xs text-red-400">{{ folderNameError }}</span>

        <div class="pt-2">
          <div class="text-sm font-medium text-gray-100">Included Chats</div>
          <div class="text-xs font-normal leading-4 text-gray-400">
            Select the chats that will appear in this folder
          </div>
          <div class="mt-4 h-52 overflow-y-auto rounded bg-gray-800/50 px-1">
            <label
              v-for="(channel, i) in editableChannels"
              :key="channel.id"
              class="flex cursor-pointer items-center justify-between space-x-2 border-gray-700 px-2 py-3 text-sm font-semibold leading-5 text-gray-100 hover:bg-gray-800"
              :class="i === editableChannels.length - 1 ? '' : 'border-b'"
            >
              <div class="flex w-full items-center space-x-2">
                <ChatImage
                  :item="channel"
                  :channel-name="getChannelName(channel)"
                  :show-online="true"
                  :is-channel="true"
                  size="xs"
                ></ChatImage>
                <BaseTooltip position="bottom">
                  <template #target>
                    <div>{{ truncate(getChannelName(channel), 65) }}</div>
                  </template>
                  <template #default v-if="getChannelName(channel).length > 65">{{ getChannelName(channel) }}</template>
                </BaseTooltip>
              </div>
              <input
                type="checkbox"
                class="h-3.5 w-3.5 cursor-pointer text-sm"
                v-model="channel.selected"
                @click="channel.selected = !channel.selected"
              />
            </label>
            <div
              v-if="editableChannels.length == 0"
              class="mx-auto flex h-full w-56 flex-col items-center justify-center space-y-2 text-gray-100"
            >
              <ChatBubbleLeftRightIcon class="h-16 w-16"></ChatBubbleLeftRightIcon>
              <div class="text-base font-semibold leading-6">No Chats Available</div>
              <div class="text-center text-sm font-medium leading-5 text-gray-400">
                When you have available chats you'll see them here
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex w-full space-x-2 px-2" :class="updateFolder ? 'justify-between' : 'justify-end'">
        <BaseButton type="danger" v-if="updateFolder" @press="deleteFolder()"> Delete </BaseButton>
        <div class="flex items-center space-x-2">
          <BaseButton :disabled="buttonBusy" type="secondary" @press="$emit('close')"> Cancel </BaseButton>
          <BaseButton :busy="buttonBusy" :disabled="disabled" @press="createFolder()">Confirm</BaseButton>
        </div>
      </div>
    </template>
  </BaseDialog>
</template>

<script setup>
import { ref, reactive, onMounted, computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import ChatImage from './ChatImage.vue';
import { ChatBubbleLeftRightIcon } from '@heroicons/vue/20/solid';
import { getChannelName } from '@/composeables/chat';
import { truncate } from '@/composeables/filters';
import useHttp from '@/composeables/http';

const store = useStore();
const $http = useHttp();
const $emit = defineEmits(['close', 'set-folder']);
const props = defineProps({
  channels: { type: Array, default: () => [] },
  updateFolder: { type: String, default: null }
});

// FOLDER
const folders = store.getters.folders;
const folderNameError = ref('');
const folderName = ref('');
const selectedFolder = ref(null);
const buttonBusy = ref(false);
const disabled = ref(false);

watch(
  () => folderName.value,
  () => {
    if (folderName.value && folderName.value.trim().length > 30) {
      folderNameError.value = 'Folder name can not exceed 30 characters';
      disabled.value = true;
    } else if (folderName.value && folderName.value.trim().length != '') {
      folderNameError.value = '';
      disabled.value = false;
    }
  }
);

async function createFolder() {
  if (folderName.value.trim().length == 0) {
    folderNameError.value = 'Please provide channel name.';
  } else if (!props.updateFolder && folders.map(x => x.text.toLowerCase()).includes(folderName.value.toLowerCase())) {
    folderNameError.value = 'Folder with this name already exists.';
  } else {
    buttonBusy.value = true;
    if (props.updateFolder) {
      await $http.put(`/messaging_folders/${selectedFolder.value.folder_id}`, {
        messaging_folder: {
          name: folderName.value,
          channels: selectedChannels.value
        }
      });
    } else {
      await $http.post('/messaging_folders', {
        messaging_folder: {
          name: folderName.value,
          channels: selectedChannels.value
        }
      });
    }
    const res = await $http.get('/messaging_folders');
    store.dispatch('setFolders', res.data.folders);
    buttonBusy.value = false;
    $emit('close');
  }
}

async function deleteFolder() {
  store.dispatch('confirm', 'Please confirm you want to remove this folder?').then(() => {
    $http.delete(`/messaging_folders/${selectedFolder.value.folder_id}`).then(() => {
      $emit('set-folder', 'all_chats');
      store.dispatch('deleteFolder', selectedFolder.value.folder_id);
      $emit('close');
    });
  });
}

onMounted(() => {
  if (props.updateFolder) {
    selectedFolder.value = folders.find(x => x.id == props.updateFolder);
    folderName.value = selectedFolder.value.text;
    editableChannels.value.map(x =>
      selectedFolder.value.channels.includes(x.cid) ? (x.selected = true) : (x.selected = false)
    );
  }
});

// CHANNELS
const requests = store.getters.requestChannels;
const editableChannels = ref(props.channels);
const selectedChannels = computed(() => {
  return editableChannels.value.filter(c => c.selected == true).map(c => c.cid);
});

onMounted(async () => {
  editableChannels.value = props.channels
    .filter(c => !requests.includes(c.cid))
    .map(x => {
      return { ...x, selected: false };
    });
});
</script>
